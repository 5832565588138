/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(window).scroll(function () {
      if ($(this).scrollTop() > 200) {
        $('header').removeClass('before-fixed-header');
        $('header').addClass('fixed-header');
      } else {
        $('header').removeClass('fixed-header');
        $('header').addClass('before-fixed-header');
      }
  });

  // Desktop Menu
  $('#main-menu ul li').hover(function(){
      $(this).find('ul:first').css({visibility: "visible",display: "none"}).slideDown(400);
      },function(){
      $(this).find('ul:first').css({visibility: "hidden",display: "none"});
  });

  $("div#mobile-menu").append($("nav.main-menu").html());
  
  $("div#mobile-menu > ul > li:has('ul') > a").before('<span class="menu-open"></span>');
  
  var 
    overlay = $(".overlay-screen");
    searchoverlay = $(".search-overlay-screen");
    menubtn = $("a#menu-btn");
    mmenu = $(".menu-block");
    searchbtn = $("a#search-btn");

  $(menubtn).on("click", function(e){
    $("body").addClass("no-scroll");
    $(overlay).addClass("is-visible");
    $(mmenu).toggleClass("is-visible");
     e.preventDefault();
  });

  $(searchbtn).on("click", function(e){
    $("body").addClass("no-scroll");
    $(searchoverlay).addClass("is-visible");
     e.preventDefault();
  });

  $('.close-menu').on("click", function(e){
    $("body").removeClass("no-scroll");
    $(overlay).removeClass("is-visible");
    $(mmenu).removeClass("is-visible");
    $(searchoverlay).removeClass("is-visible");
     e.preventDefault();
  });

  $(overlay).on("click", function(e) {
    $(this).removeClass("is-visible");
    $("html").removeClass("no-scroll");
    $("body").removeClass("no-scroll");
    $(mmenu).removeClass("is-visible");
    e.preventDefault();
  });
  
  

  $(".menu-open").click(function() {
      $(this).parent().children('ul.sub-menu').slideToggle();
       $(this).parents('li.menu-item-has-children').toggleClass('active').siblings().removeClass('active');
      $('.menu-open').not(this).parent().children('ul.sub-menu').slideUp();
      $(this).toggleClass('more');
      $('.menu-open').not(this).removeClass('more');
  });


  $('.wp-block-image').magnificPopup({
        type: 'image',
        delegate: 'a',
        mainClass: 'mfp-fade',
        preloader: true,
        closeOnBgClick: false,
        gallery: {
          enabled: true,
          navigateByImgClick: true
        }
    });

})(jQuery); // Fully reference jQuery after this point.
